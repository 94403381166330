<template>
	<div class="my-quizzes">
		<div class="top">
			<h3>{{ translate('myQuizzes') }}</h3>

			<div>
				<div class="checkbox">
					<input id="hidePublicQuizzes" v-model="hidePublicQuizzes" type="checkbox" />
					<label for="hidePublicQuizzes">
						<span class="box">
							<Icon name="checkmark" width="14" />
						</span>
						<span class="text" v-html="translate('hidePublicQuizzes')"></span>
					</label>
				</div>

				<button class="small icon-left" @click="builder()">
					<Icon name="plus" class="white" /><span>{{ translate('createNewQuiz') }}</span>
				</button>
			</div>
		</div>

		<transition name="fade">
			<div v-if="showEmptyNotice" class="alert text-center extra-padding full-width">
				<p v-html="translate('myQuizzes.notice.noQuizzes')"></p>
			</div>
		</transition>

		<div class="quizzes">
			<div v-for="quiz in myQuizzes" :key="quiz.id" class="quiz">
				<div class="inner" @click="builder(quiz.qid)">
					<h4>{{ quiz.title }}</h4>
					<div class="tags">
						<p v-tippy class="created" :content="translate('quiz.created')">
							{{ new Date(quiz.created_at).toLocaleDateString('sv-SE') }}
						</p>
						<p
							v-tippy
							:class="[{ public: quiz.public }, 'status']"
							:content="translate('quiz.privacy')">
							{{
								quiz.public ? translate('quiz.privacy.public') : translate('quiz.privacy.private')
							}}
						</p>
					</div>
					<div class="meta">
						<div class="questions">
							<Icon name="question-mark-circle" width="18" />
							<p>
								{{ quiz.questions }}
								{{
									quiz.questions === 1
										? translate('myQuizzes.question')
										: translate('myQuizzes.questions')
								}}
							</p>
						</div>
						<div class="completed-by">
							<Icon name="checkmark-circle-2" width="18" />
							<p>{{ translate('myQuizzes.completedBy', null, quiz.completed_by) }}</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapMutations, mapActions } from 'vuex'
import { auth, db } from '@/lib/supabase'
import { handleError } from '@/utils/utils'
import Icon from './Icon.vue'

export default {
	components: { Icon },

	data() {
		return {
			quizzes: [],
			showEmptyNotice: false,
		}
	},

	computed: {
		hidePublicQuizzes: {
			get() {
				return this.$store.state.hidePublicQuizzes
			},
			set(val) {
				this.updateHidePublicQuizzes(val)
				this.setLocalStorage()
			},
		},
		myQuizzes() {
			return this.hidePublicQuizzes ? this.quizzes.filter((q) => !q.public) : this.quizzes
		},
	},

	async beforeMount() {
		this.quizzes = await this.getQuizzes()
		if (this.quizzes.length === 0) {
			this.showEmptyNotice = true
		}
	},

	methods: {
		...mapMutations(['updateHidePublicQuizzes']),
		...mapActions(['setLocalStorage']),
		builder(id) {
			if (id) {
				this.$router.replace(`/builder/${id}`)
			} else {
				this.$router.replace('/builder')
			}
		},
		async getQuizzes() {
			const { data: quizzes, error } = await db.rpc('get_user_quizzes', {
				user_id_param: auth.user().id,
			})
			if (error) handleError(error)
			else return quizzes
		},
		quizUrl(id) {
			return `${window.location.origin}/q/${id}`
		},
	},
}
</script>

<style lang="scss" scoped>
.my-quizzes {
	margin-top: 75px;
	position: relative;
	text-align: left;

	@include mobile {
		margin-top: 50px;
	}

	.top {
		align-items: center;
		display: flex;
		justify-content: space-between;

		> div {
			align-items: center;
			display: flex;

			.checkbox {
				@include mobile {
					left: 0;
					position: absolute;
					transform: translateY(175%);
				}

				label .text {
					color: darken(white, 50%);
				}
			}

			button {
				margin-left: 20px;

				@include mobile {
					padding: 4px 8px;

					::v-deep .icon i {
						margin: 0;
					}

					span {
						display: none;
					}
				}
			}
		}
	}

	.alert {
		margin-top: 10px;
	}

	.quizzes {
		$spacing: 15px;

		display: flex;
		flex-wrap: wrap;
		margin: 10px -#{$spacing} 0;

		@include mobile {
			margin-top: 50px;
		}

		.quiz {
			padding: $spacing;
			width: 33.333333%;

			@include mobile {
				width: 100%;
			}

			.inner {
				background: darken(white, 5%);
				border-radius: $border-radius;
				cursor: pointer;
				display: flex;
				flex-direction: column;
				height: 100%;
				padding: 20px;
				position: relative;
				transition: $transition;

				&:hover {
					background: darken(white, 10%);
				}
			}

			h4 {
				align-items: center;
				display: flex;
				font-size: 18px;
				line-height: 1.4;
				margin-bottom: 4px;
			}

			.tags {
				display: flex;
				left: 10px;
				margin-bottom: 10px;
				position: absolute;
				top: 0;
				transform: translateY(-50%);

				p {
					background: darken(white, 20%);
					border-radius: $border-radius;
					color: rgba($font-color, 0.75);
					font-size: 12px;
					font-weight: 600;
					padding: 1px 6px;

					+ p {
						margin-left: 10px;
					}

					&.status {
						background: lighten($yellow, 5%);

						&.public {
							background: $green;
							color: white;
						}
					}
				}
			}

			.meta {
				font-size: 14px;
				margin-top: auto;

				> div {
					align-items: center;
					display: flex;

					::v-deep .icon i {
						margin-right: 6px;
						opacity: 0.5;
						top: 2px;
					}
				}
			}

			p {
				display: block;
			}
		}
	}
}
</style>
